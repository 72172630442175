import { Link, navigate } from "gatsby"
import _ from "lodash"
import PropTypes from "prop-types"
import React, { useState } from "react"
import UIkit from "uikit"
import Card from "./card"

const ProductsGroups = ({
  items,
  category,
  multiSelect,
  productDescription,
}) => {
  const [selectedItem, setSelectedItem] = useState(null)

  const handleClick = (group, item) => {
    item.group = group.name
    if ((selectedItem || {})._id === item._id) {
      setSelectedItem(null)
      return
    }
    if (!selectedItem || (selectedItem || {}).group === item.group) {
      setSelectedItem(item)
      UIkit.notification.closeAll()
      UIkit.notification(
        `Product selected from ${group.name}. Now select a product from ${
          (groups.find(_group => _group.name !== group.name) || {}).name
        }.`,
        { pos: "bottom-center", status: "success" }
      )
    } else {
      UIkit.notification.closeAll()
      navigate(
        `/category/${category._id}/products/${
          selectedItem.group.toLowerCase().includes("outdoor")
            ? `${selectedItem._id}+${item._id}`
            : `${item._id}+${selectedItem._id}`
        }`
      )
    }
  }

  let groups = _.groupBy(items, "subcategory")
  groups = Object.keys(groups).map(key => ({
    name: key,
    description:
      productDescription && groups[key].length && groups[key][0].description,
    items: groups[key],
  }))
  return (
    <div className="product-groups">
      {groups.map((group, i) => {
        return (
          <div key={`group_${i}`} className="uk-section-small">
            <h2 className="line-break">
              {group.name}{" "}
              {multiSelect && (selectedItem || {}).group !== group.name && (
                <span
                  style={{ position: "absolute", marginLeft: "20px" }}
                  className="uk-badge blink"
                >
                  Select One
                </span>
              )}
            </h2>
            {group.description ? (
              <p className="description">{group.description}</p>
            ) : (
              <></>
            )}
            <div
              className="uk-grid-match uk-flex uk-flex-wrap uk-flex-center"
              data-uk-grid
            >
              {group.items.map((item, i) => {
                return multiSelect ? (
                  <div
                    key={`product__${item._id}`}
                    className={`uk-link-reset uk-pointer card-wrapper uk-width-1-${
                      group.items.length
                    } ${
                      selectedItem && selectedItem._id === item._id
                        ? "selected"
                        : ""
                    }`}
                    style={{ maxWidth: "400px", minWidth: "280px" }}
                    role="button"
                    tabIndex={i}
                    onClick={handleClick.bind(this, group, item)}
                  >
                    <Card item={item} />
                  </div>
                ) : (
                  <Link
                    to={`/category/${category._id}/products/${item._id}`}
                    key={`product__${item._id}`}
                    className={`uk-link-reset uk-width-1-${group.items.length}`}
                    style={{ maxWidth: "400px", minWidth: "280px" }}
                  >
                    <Card item={item} />
                  </Link>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

ProductsGroups.propTypes = {
  items: PropTypes.array,
  category: PropTypes.object,
  multiSelect: PropTypes.bool,
  productDescription: PropTypes.bool,
}

export default ProductsGroups
